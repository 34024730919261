// playSound.js

const playSound = (url, secs = null) => {
  return new Promise((resolve, reject) => {
    const audio = new Audio(url);
    audio.onended = resolve; // Resolve the promise when the audio ends
    audio.onerror = reject; // Reject the promise if an error occurs

    if (secs !== null) {
      // Stop the audio after the specified number of seconds
      setTimeout(() => {
        audio.pause();
        audio.currentTime = 0; // Reset to the beginning
        resolve(); // Resolve the promise
      }, secs * 1000); // Convert seconds to milliseconds
    }

    audio.play();
  });
};

export default playSound;
