// src/utils/useStyles.js
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonSubscribed: {
    backgroundColor: '#8a2be2',
    color: 'white',
  },
  buttonDefault: {
    backgroundColor: '#3f51b5',
    color: 'white',
  },
  tooltip: {
    fontSize: theme.typography.pxToRem(18),
  },
  menu: {
    backgroundColor: (props) => (props.darkTheme ? 'black' : 'inherit'),
    color: (props) => (props.darkTheme ? 'white' : 'inherit'),
  },
  menuItem: {
    backgroundColor: (props) => (props.darkTheme ? 'black' : 'inherit'),
    color: (props) => (props.darkTheme ? 'white' : 'inherit'),
    boxShadow: (props) =>
      props.darkTheme ? '0 0 5px 2px rgba(255, 255, 255, 0.2)' : 'none',
  },
}));

export default useStyles;
