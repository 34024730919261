import React, { useContext, useEffect, useState, useRef } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useConfettiText } from '../../hooks/useConfettiText';
import './happyBirthday.css';
const useIsBirthday = (player) => {
  const today = new Date();

  // Log player data to ensure values are correct
  console.log('Player Birth Month:', player.data.player.bMonth);
  console.log('Player Birth Day:', player.data.player.bDay);

  // Check if player.bMonth and player.bDay are valid numbers
  if (isNaN(player.data.player.bMonth) || isNaN(player.data.player.bDay)) {
    console.error(
      'Invalid birth month or day:',
      player.data.player.bMonth,
      player.data.player.bDay,
    );
    return false;
  }

  const birthDate = new Date(
    today.getFullYear(),
    player.data.player.bMonth - 1,
    player.data.player.bDay,
  );

  console.log('Birth Date:', birthDate.getMonth(), birthDate.getDate());
  console.log('Today:', today.getMonth(), today.getDate());

  return (
    today.getMonth() === birthDate.getMonth() &&
    today.getDate() === birthDate.getDate()
  );
};

const HappyBirthday = React.memo(() => {
  const { player } = useContext(AuthContext);
  console.log(player);
  const [isBirthday, setIsBirthday] = useState(false);
  const inputRef = useRef(null);

  const [showConfettiText, ConfettiTextComponent] = useConfettiText(
    inputRef,
    false, // Assuming desktop view for simplicity
    'Happy Birthday!',
    200,
    'birthday',
    false,
  );

  const birthday = useIsBirthday(player);
  console.log("Is it the player's birthday?", birthday);

  useEffect(() => {
    const birthdayChecked = sessionStorage.getItem('birthdayChecked');
    if (!birthdayChecked && birthday) {
      setIsBirthday(true);
      showConfettiText();
      sessionStorage.setItem('birthdayChecked', 'true');
    }
  }, [birthday, showConfettiText]);

  return (
    <div ref={inputRef} className="centeredHappyBirthday">
      {isBirthday && (
        <span
          className="centeredHappyBirthday flash"
          style={{ fontSize: '.5em' }}
        >
          Happy Birthday, {player.data.player.playerId}! 🎂🎉🥳
        </span>
      )}
      <ConfettiTextComponent />
    </div>
  );
});

export default HappyBirthday;
